import {InlineStack, SkeletonDisplayText} from '@shopify/polaris-internal';
import React from 'react';

import styles from './SkeletonIndexFilters.module.css';

export function SkeletonIndexFilters() {
  return (
    <div className={styles.SkeletonIndexFilters}>
      <div className={styles.TabsWrapper}>
        <div className={styles.Tab}>
          <SkeletonDisplayText size="medium" />
        </div>
        <div className={styles.Tab}>
          <SkeletonDisplayText size="medium" />
        </div>
      </div>
      <InlineStack gap="200">
        <div className={styles.Filter}>
          <SkeletonDisplayText size="medium" />
        </div>
        <div className={styles.Search}>
          <SkeletonDisplayText size="medium" />
        </div>
      </InlineStack>
    </div>
  );
}
